import { Col, Container, Row } from "react-bootstrap";

import "./About.css";
import Banner from "../Banner/Banner";

function About() {
  return (
    <Container fluid>
      <Row id="About" className="align-items-center justify-content-center">
        <Col xs="11" sm="10" md="8">
          <h1>
            The history of <span className="greenText">Fells Code</span>
            <hr />
          </h1>
          <p>Fells Code has been in business since 2015.</p>

          <p>
            We seek to be a prominent player in the software services industry,
            specializing in enterprise software solutions and services. With a
            passion for innovation and a commitment to excellence, we are
            dedicated to providing cutting-edge technology solutions that
            empower businesses to thrive in the digital age.
          </p>

          <p>
            At Fells Code, we understand the unique challenges faced by modern
            enterprises, and our mission is to transform those challenges into
            opportunities through tailored software solutions. With a team of
            experienced professionals and a customer-centric approach, we
            collaborate closely with our clients to analyze their specific needs
            and deliver customized software solutions that drive growth, enhance
            efficiency, and maximize their competitive advantage.
          </p>

          <p>
            By harnessing the power of the latest technologies and industry best
            practices, we empower businesses to streamline their operations,
            optimize processes, and unlock new avenues for success. What sets
            Fells Code apart is our unwavering commitment to delivering
            exceptional results. We prioritize long-term partnerships with our
            clients, working hand-in-hand to understand their goals and
            challenges. Through close collaboration, we ensure that our software
            solutions align with their strategic objectives and deliver tangible
            value. Our agility and adaptability enable us to navigate the
            dynamic landscape of the software services industry and provide our
            clients with the most relevant and impactful solutions.
          </p>
        </Col>
      </Row>
      <Banner type="orange" />
    </Container>
  );
}

export default About;
