import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./Landing.css";

function Landing() {
  return (
    <Container fluid id="Landing">
      <Row
        className="justify-content-center align-items-center"
        style={{ height: "inherit" }}
      >
        <Col id="brandDiv" xs="8"></Col>
      </Row>
    </Container>
  );
}

export default Landing;
