import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoBanner from "../../images/logoBanner.png";
import "./Footer.css";

function Footer() {
  return (
    <Container fluid>
      <Row id="Footer" className="justify-content-center py-5">
        <Col xs="12" md="4" className="text-start">
          <Link to={"/"}>
            <img src={logoBanner} alt="Fells Code logo" width="50%" />
          </Link>
        </Col>
        <Col xs="12" md="4" className="text-center">
          &copy; 2023 | <Link to="/privacy">Privacy Information</Link> |{" "}
          <a href="mailto:contact@fellscode.com">Contact</a>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
