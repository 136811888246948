import { Col, Container, Row } from "react-bootstrap";

import pencil from "../../images/pencils.png";
import develop from "../../images/laptop.png";
import cloud from "../../images/deploy.png";
import Banner from "../Banner/Banner";

import "./Services.css";

function Services() {
  return (
    <Container fluid>
      <Row id="Services" className="align-items-center">
        <Col xs="12">
          <Row className="justify-content-center">
            <Col xs="10">
              <h1 className="text-center pt-4">
                <span className="yellowText">Fells Code</span> Core Values
                <hr />
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="4" className="text-center">
              <img src={pencil} alt="" width="200" />
              <h3 className="blueText">Customer Centric Design</h3>
              <p>
                Too often, solutions are designed with the intent of the
                inventor at it's forefront. The solution is crafted with the
                mindset "What I think is best". We let the customer drive the
                solution.
              </p>
            </Col>
            <Col xs="12" md="4" className="text-center">
              <img src={develop} alt="" width="200" />
              <h3 className="blueText">Innovative Coding Practices</h3>
              <p>
                Large companies biggest flaw is inflexibility. Time, money, and
                effort is not devoted to ensuring that their employees are
                actually growing and that the software they host is evolving
                with modern security and innovation. At Fells Code, we ensure
                that our software is driven by innovation, security, and our
                developers. Who we prioritize their growth over profit.
              </p>
            </Col>
            <Col xs="12" md="4" className="text-center">
              <img src={cloud} alt="" width="200" />
              <h3 className="blueText">User Defined Deployments</h3>
              <p>
                Cloud solutions, subscriptions, or one time application
                distrubution. You get to decide. Every compoany is different. We
                don't put them in boxes. We don't presume what is best for your
                business model. We think the same software should be avaliable
                to all, in any platform, in any way.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Banner type="red" />
    </Container>
  );
}

export default Services;
