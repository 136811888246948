import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Landing from "./components/Landing/Landing";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Socials from "./components/Socials/Socials";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <>
      <Landing />
      <About />
      <Services />
      <Socials />
      <Footer />
    </>
  );
}

export default App;
